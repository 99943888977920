<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col
          class="pt-0"
          v-for="(item, index) in style_form"
          :key="index"
          :cols="item.col"
          :id="item.col_id"
          v-if="item.hasOwnProperty('visible') ? item.visible : true"
        >
          <label class="text-xs text-typo font-weight-bolder ms-1">{{
            item.label
          }}</label>
          <!-- :required="item.required" -->

          <v-checkbox
            v-if="item.type == 'checkbox'"
            :rules="item.rules"
            class="mt-0"
            v-model="form_data[item.value_text]"
            :color="item.color"
            :label="item.label_input"
            :value="form_data[item.value_text]"
            :error-messages="item.error"
            @change="action_checkbox(item, $event)"
            :disabled="item.disabled"
          ></v-checkbox>

          <v-autocomplete
            v-else-if="item.type == 'autocomplete'"
            outlined
            dense
            v-model="form_data[item.value_text]"
            :items="item.items"
            :item-text="item.title_select ? item.title_select : 'name'"
            :item-value="'id'"
            :placeholder="$t('general.choose')"
            :error-messages="item.error"
            :multiple="item.type_select == 'multiple' ? true : false"
            @change="action_selectbox(item, $event)"
            :disabled="item.disabled"
          ></v-autocomplete>

          <v-select
            v-else-if="item.type == 'select'"
            v-model="form_data[item.value_text]"
            :items="item.items"
            :item-text="item.title_select ? item.title_select : 'name'"
            item-value="id"
            :label="$t('general.choose')"
            :error-messages="item.error"
            dense
            :rules="item.rules"
            :multiple="item.type_select == 'multiple' ? true : false"
            outlined
            :disabled="item.disabled"
            @change="action_selectbox(item, $event)"
          ></v-select>

          <v-file-input
            v-else-if="item.type == 'file'"
            v-model="form_data[item.value_text]"
            :multiple="item.type_file == 'multiple' ? true : false"
            :rules="item.rules"
            :accept="item.accept"
            show-size
            :error-messages="item.error"
            counter
            :disabled="item.disabled"
            chips
          ></v-file-input>
          <!-- @change="action_file(item, $event)" -->

          <!-- label="Avatar" -->

          <v-textarea
            v-else-if="item.type == 'textarea'"
            :label="item.label"
            auto-grow
            outlined
            :rows="item.row"
            row-height="15"
            :error-messages="item.error"
            :rules="item.rules"
            v-model="form_data[item.value_text]"
            :disabled="item.disabled"
          ></v-textarea>

          <html-editor
            v-else-if="item.type == 'editor'"
            row="10"
            v-model="form_data[item.value_text]"
          ></html-editor>

          <v-text-field
            v-else
            :rules="item.rules"
            :type="item.type"
            :error-messages="item.error"
            outlined
            :min="item.min"
            color="rgba(0,0,0,.6)"
            :placeholder="item.placeholder"
            class="font-size-input placeholder-lighter text-light-input"
            v-model="form_data[item.value_text]"
            :disabled="item.disabled"
          >
          </v-text-field>

          {{ item.data }}
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 text-right">
          <!-- if btn false will be appered in form -->
          <v-btn
            v-if="!btn"
            :ripple="false"
            color="white"
            class="btn-primary py-5 px-6 mb-0"
            text
            :loading="$store.state.form.loader"
            @click="save()"
          >
            <span slot="loader">
              <v-progress-circular
                style="width: 20px; height: 20px"
                indeterminate
                color="white"
              ></v-progress-circular>
            </span>
            {{ $t("form.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import HtmlEditor from "../../../components/HtmlEditor.vue";
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } = createNamespacedHelpers("form");
export default {
  components: {
    HtmlEditor,
  },
  props: { btn: Boolean },
  name: "Form",
  data() {
    return {
      valid: true,
      validation: false,
      data: [],
    };
  },
  computed: {
    ...mapState(["style_form", "form_data", "file_image"]),
  },
  methods: {
    ...mapMutations(["SET_LOADER", "SET_ACTION", "INFO_CALL"]),
    ...mapActions(["saveFormData"]),
    action_selectbox(item, $event) {
      if (item.action) {
        this.$store.commit("form/SET_ACTION", $event);
        this.$store.dispatch(
          `${this.$store.state.form.collection}/${item.action}`
        );
      }
    },
    action_checkbox(item, $event) {
      if (item.action) {
        // this.$store.commit("form/SET_ACTION", $event);
        this.$store.dispatch(
          `${this.$store.state.form.collection}/${item.action}`,
          $event
        );
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    // action_file(item, $event) {
    //   if (item.action) {
    //     this.SET_ACTION($event);
    //     this.saveFormData({
    //       name: "form/setFile",
    //     });
    //   }
    // },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    save: function () {
      if (this.validate()) {
        this.validation = true;
      } else {
        this.validation = false;
      }
      this.SET_LOADER(true);
      this.saveFormData(this.$store.state.form.info_call);
    },
    setData() {
      this.resetValidation();

      // this.data = this.$store.state.form.style_form
      // this.form_data = this.$store.state.form.form_data
    },
  },

  mounted() {
    this.setData();
  },
};
</script>