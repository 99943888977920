<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent :max-width="data.size">
        <v-card>
          <v-toolbar color="info" dark class="header-info">{{
            data.title
          }}</v-toolbar>

          <v-card-text class="mt-5">
            <v-container>
              <Form :btn="true" ref="myFormModal"></Form>
            </v-container>
          </v-card-text>
          <v-card-actions class="border-gray justify-end">
            <!-- <v-spacer></v-spacer> -->
            <v-btn color="dark" class="bg-light" text @click="close()">
              {{ $t("form.Close") }}
            </v-btn>

            <v-btn
              :loading="loader"
              class="btn-primary mw-80"
              color="white"
              text
              @click="save_form()"
            >
              <span slot="loader">
                <v-progress-circular
                  style="width: 20px; height: 20px"
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </span>
              {{ $t("form.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations, mapActions } = createNamespacedHelpers("form");
import Form from "../New/Form.vue";
export default {
  props: {
    data: {},
    btn: {
      type: Boolean,
      default: true,
    },
  },
  name: "Modal",
  components: {
    Form,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["info_call", "loader", "dialog"]),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    ...mapMutations([
      "SET_LOADER",
      "RESET_DATA_FORM",
      "SET_DIALOG",
      "RESET_ERROR",
    ]),
    ...mapActions(["saveFormData"]),
    close: function () {
      this.SET_DIALOG(false);
      this.SET_LOADER(false);
      this.RESET_ERROR();
      this.$refs.myFormModal.reset();
    },
    save_form: function () {
      this.SET_LOADER(true);
      // send name function
      this.saveFormData(this.info_call);
    },
  },
};
</script>
<style>
.border-gray {
  border: 1px solid #ccc;
}
.v-dialog > .v-card > .v-card__text {
  padding-bottom: 0px;
}
</style>